import React, { useState, useEffect } from "react";
import "./AddEducator.scss";
import NavigateBack from "../../../CommonScreens/NavigateBack/NavigateBack";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import downArrow from "../../../../Assets/commonImg/select-dropdown-arrow.svg";
import passIcon from "../../../../Assets/commonImg/view.svg";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getAddress } from "../../../../Services/AddressService";
import { SelectCollegeModal } from "../../../../Components/SelectCollegeModal/SelectCollegeModal";
import { createEducator, getEducatorById, updateEducator } from "../../../../Services/EducatorService";
import Swal from "sweetalert2";
import { parse, format } from 'date-fns';
import { color } from "highcharts";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	// bgcolor: 'background.paper',
	border: "none",
	// boxShadow: 24,
	background: "#FFFFFF 0% 0% no-repeat padding-box",
	boxShadow: "0px 3px 6px #00000029",
	borderRadius: "8px",
	padding: "10px",
};

export default function AddEducator({ sideNavState, setSideNavState }) {
	const location = useLocation();
	const navigate = useNavigate();
	const type = location.state?.type;
	const { id } = useParams();
	const [isAllFieldDisabled, setAllFieldDisabled] = useState(false);
	const initialState = {
		username: '',
		password: '',
		firstName: '',
		middleName: '',
		lastName: '',
		fullName: '',
		birthdate: '',
		gender: '',
		mobile: '',
		prefix: '+91',
		primaryEmail: '',
		secondaryEmails: '',
		country: '',
		stateProvince: '',
		city: '',
		postalCode: '',
		qualification: '',
		collegeName: '',
		collegeId: '',
	};


	const [selectedFile, setSelectedFile] = useState(null);
	const [formData, setFormData] = useState(initialState);
	const [countryList, setCountryList] = useState([]);
	const [statesList, setStates] = useState([]);
	const [citiesList, setCities] = useState([]);

	const { username, password, firstName, middleName, lastName, fullName, birthdate, gender, mobile, prefix, primaryEmail, secondaryEmails, country, stateProvince, city, postalCode, qualification, collegeName, collegeId, } = formData;
	useEffect(() => {
		getCountry();
		if (type && id) {
			if (type.type === "edit") {
				setAllFieldDisabled(false)
			} else {
				setAllFieldDisabled(true)
			}
			getEducatorDetailsById(id);
		}
	}, []);

	const getEducatorDetailsById = () => {
		getEducatorById(id)
			.finally(() => { })
			.then(async (resData) => {
				if (typeof resData === 'object' && resData.hasOwnProperty('data')) {
					const educatorDetails = resData.data;
					const parsedDate = new Date(educatorDetails?.birthdate);
					// setFormData({ ...formData, country: educatorDetails.country })
					if (educatorDetails?.country) {
						await handleCountryInput(educatorDetails.country);
					}
					// setFormData({ ...formData, stateProvince: educatorDetails.stateProvince })
					if (educatorDetails?.stateProvince) {
						await handleStateInput(educatorDetails.stateProvince, educatorDetails.country);
					}
					const patchData = {
						username: educatorDetails.username,
						password: '',
						firstName: educatorDetails.firstName,
						middleName: educatorDetails.middleName,
						lastName: educatorDetails.lastName,
						fullName: educatorDetails.fullName,
						birthdate: !isNaN(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : '',
						gender: educatorDetails.gender,
						prefix: educatorDetails?.mobile.length > 10 ? educatorDetails?.mobile?.substring(0, educatorDetails?.mobile.length - 10) : '',
						mobile: educatorDetails?.mobile.length > 10 ? educatorDetails?.mobile?.substring(educatorDetails?.mobile.length - 10) : educatorDetails?.mobile,
						primaryEmail: educatorDetails.primaryEmail,
						secondaryEmails: educatorDetails.secondaryEmails,
						country: educatorDetails.country,
						stateProvince: educatorDetails.stateProvince,
						city: educatorDetails.city,
						postalCode: educatorDetails.postalCode,
						qualification: educatorDetails.qualification,
						collegeName: educatorDetails?.collegeName,
						collegeId: educatorDetails.collegeId,
					};
					setFormData((previousData) => {
						return { ...previousData, ...patchData };
					});
				}
			})
			.catch((error) => {
				console.log("error getEducatorById", error);
			});
	};

	const handleFileInputChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const [openCollege, setCollegeOpen] = React.useState(false);
	const handleOpen = () => setCollegeOpen(true);
	const handleClose = () => setCollegeOpen(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onFormValueChange = (key, value) => {
		const obj = { [key]: value };
		console.log(`🚀 ~ onFormValueChange ~ obj:`, obj)

		if (key === 'country') {
			const formValueData = { ...formData, country: value, stateProvince: '', city: '' };
			setFormData(formValueData);
			setStates([]);
			setCities([]);
			handleCountryInput(value)
			return;
		}
		if (key === 'stateProvince') {
			setFormData({ ...formData, stateProvince: value, city: '' });
			setCities([]);
			handleStateInput(value, country)
			return
		}

		setFormData((prevState) => {
			const updatedFormValues = { ...prevState, ...obj };
			updatedFormValues.fullName = `${updatedFormValues?.firstName} ${updatedFormValues?.middleName} ${updatedFormValues?.lastName}`.trim();
			return updatedFormValues;
		});
	};

	const getCountry = () => {
		getAddress({ addressCategory: "country" })
			.finally(() => { })
			.then((res) => {
				if (res?.data && Array.isArray(res?.data)) {
					setCountryList(res?.data);
				} else {
					setCountryList([]);
				}
			})
			.catch((error) => {
				setCountryList([]);
				console.log("🚀 ~ getAddress ~ error:", error);
			});
	};

	const handleStateInput = (state, country) => {
		getAddress({ addressCategory: "city", countryName: country, stateName: state })
			.finally(() => { })
			.then((res) => {
				if (res?.data && Array.isArray(res?.data)) {
					setCities(res?.data);
				} else {
					setCities([]);
				}
			})
			.catch((error) => {
				setCities([]);
				console.log("🚀 ~ getAddress ~ error:", error);
			});
	};

	const handleCountryInput = (event) => {
		getAddress({ addressCategory: "state", countryName: event })
			.finally(() => { })
			.then((res) => {
				if (res?.data && Array.isArray(res?.data)) {
					setStates(res?.data);
					console.log("🚀 ~ getAddres?.datas ~ res?.data:", res?.data);
				} else {
					setStates([]);
				}
			})
			.catch((error) => {
				setStates([]);
				console.log("🚀 ~ getAddress ~ error:", error);
			});
	};

	const setCollegeName = (value) => {
		console.log(`🚀 ~ setCollegeName ~ value:`, value)
		setFormData((prevState) => {
			const updatedFormValues = { ...prevState, ...value };
			updatedFormValues.fullName = `${updatedFormValues?.firstName} ${updatedFormValues?.middleName} ${updatedFormValues?.lastName}`.trim();
			return updatedFormValues;
		});
		console.log(`🚀 ~ onFormValueChange ~ formData:`, formData)
	}

	const onSaveCollegeAdmin = (e) => {
		e.preventDefault();
		const formValues = Object.assign({}, formData);
		formValues.birthdate = new Date(formValues.birthdate).getTime();
		formValues.mobile = formValues.prefix + formValues.mobile;

		const operationType = type?.type || "create";

		if (!formValues.firstName || formValues.firstName.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "First Name is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		} else if (!formValues.birthdate) {
			Swal.fire({
				title: "Empty",
				text: "Birth date is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.gender || formValues.gender.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "Gender is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.mobile || formValues.mobile.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "Mobile Number is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.primaryEmail || formValues.primaryEmail.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "primary email is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.username || formValues.username.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "username is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (operationType !== "edit" && (!formValues.password || formValues.password.trim() === "")) {
			Swal.fire({
				title: "Empty",
				text: "password is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.collegeId || formValues.collegeId.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "College is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else {
			if (!type) {
				createEducator({ ...formValues })
					.finally(() => {
						console.log("🚀 ~ createCollege ~ finally:");
					})
					.then((res) => {
						Swal.fire({
							title: "Successful",
							text: "New Educator Added Successful!",
							icon: "success",
							confirmButtonColor: "#3085d6",
							confirmButtonText: "Okay"
						}).finally(() => {
							navigate('/home/viewEducator', { replace: true });
						})
					})
					.catch((error) => {
						if (error?.response?.data) {
							Swal.fire({
								title: "Error",
								text: error.response.data,
								icon: "error",
								confirmButtonColor: "#3085d6",
								confirmButtonText: "Okay"
							})
						} else {
							Swal.fire({
								title: "Error",
								text: "Something went wrong please try again",
								icon: "error",
								confirmButtonColor: "#3085d6",
								confirmButtonText: "Okay"
							})
						}
					});
			} else if (operationType === "edit") {
				delete formValues.password;
				updateEducator(id, formValues)
					.then((res) => {
						console.log("🚀 ~ createCollege ~ res:", res);
						Swal.fire({
							title: "Successful",
							text: "Educator Update Successful!",
							icon: "success",
							confirmButtonColor: "#3085d6",
							confirmButtonText: "Okay"
						}).finally(() => {
							navigate('/home/viewEducator', { replace: true });
						})
					})
					.catch((error) => {
						if (error?.response?.data) {
							Swal.fire({
								title: "Error",
								text: error.response.data,
								icon: "error",
								confirmButtonColor: "#3085d6",
								confirmButtonText: "Okay"
							})
						} else {
							Swal.fire({
								title: "Error",
								text: "Something went wrong please try again",
								icon: "error",
								confirmButtonColor: "#3085d6",
								confirmButtonText: "Okay"
							})
						}
					});
			}
		}
	};

	const onResetCollegeAdmin = () => {
		setFormData(initialState);
	};
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const inputType = isPasswordVisible ? 'text' : 'password';
	const togglePasswordVisibility = () => {
		setIsPasswordVisible(prevState => !prevState);
	};

	let passwordField = <></>;
	if (!type?.type) {
		passwordField = (
			<div className="col-md-4 col-12">
				<p className="input-label">Password<b style={{ color: 'red' }}>*</b></p>
				<div className="input-container" style={{ position: 'relative' }}>
					<input
						className="custom-input"
						type={inputType}
						name="password"
						value={password}
						onChange={(event) => {
							const key = event.target.name;
							const value = event.target.value;
							onFormValueChange(key, value);
						}}
						disabled={isAllFieldDisabled}
						style={{ paddingRight: '2rem' }}
					/>
					<img
						src={passIcon}
						alt="Toggle Password Visibility"
						onClick={togglePasswordVisibility}
						style={{
							position: 'absolute',
							right: '10px',
							top: '40%',
							transform: 'translateY(-50%)',
							cursor: 'pointer'
						}}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className={"educator-container " + (sideNavState ? "fullWidthOpenSideNav" : "halfWidthOpenSideNav")}>
			<NavigateBack />
			<div className="educator-form-card">
				<div className="educator-form-title">
					<p className="headingTheme">Educator Profile</p>
				</div>
				<div className="row mx-0">
					<div className="col-12">
						<div className="row">
							<div className="col-md-4 col-12">
								<p className="input-label">First Name <b style={{ color: 'red' }}>*</b></p>
								<input
									className="custom-input"
									type="text"
									name="firstName"
									value={firstName}
									onChange={(event) => {
										const key = event.target.name;
										const value = event.target.value;

										onFormValueChange(key, value);
									}}
									disabled={isAllFieldDisabled}
								/>
							</div>
							<div className="col-md-4 col-12">
								<p className="input-label">Middle Name</p>
								<input
									className="custom-input"
									type="text"
									name="middleName"
									value={middleName}
									onChange={(event) => {
										const key = event.target.name;
										const value = event.target.value;
										onFormValueChange(key, value);
									}}
									disabled={isAllFieldDisabled}

								/>
							</div>
							<div className="col-md-4 col-12">
								<p className="input-label">Last Name</p>
								<input
									className="custom-input"
									type="text"
									name="lastName"
									value={lastName}
									onChange={(event) => {
										const key = event.target.name;
										const value = event.target.value;
										onFormValueChange(key, value);
									}}
									disabled={isAllFieldDisabled}

								/>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="row">
							<div className="col-md-8 col-12">
								<p className="input-label">Full Name</p>
								<input className="custom-input" disabled={true} type="text" name="fullName" value={fullName} />
							</div>
							<div className="col-md-4 col-12">
								<p className="input-label">Date Of Birth<b style={{ color: 'red' }}>*</b></p>
								<input
									className="custom-input"
									type="date"
									name="birthdate"
									value={birthdate}
									onChange={(event) => {
										const key = event.target.name;
										const value = event.target.value;
										onFormValueChange(key, value);
									}}
									disabled={isAllFieldDisabled || (type && type.type == 'edit')}

								/>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="row">
							<div className="col-md-4 col-12">
								<p className="input-label">Gender<b style={{ color: 'red' }}>*</b></p>
								<select
									className="custom-input"
									name="gender"
									value={gender}
									onChange={(event) => {
										const key = event.target.name;
										const value = event.target.value;
										onFormValueChange(key, value);
									}}
									disabled={isAllFieldDisabled}

								>
									<option value="" selected disabled>
										Select Gender
									</option>
									<option value="Male">Male</option>
									<option value="Female">Female</option>
								</select>
							</div>
							<div className="col-md-4 col-12">
								<p className="input-label">Mobile No.<b style={{ color: 'red' }}>*</b></p>
								<div className="row">
									<div className="col-2">
										<input className="custom-input px-2" name="prefix" value={prefix} defaultValue={'+91'}
											onChange={(event) => {
												const key = event.target.name;
												const value = event.target.value;
												onFormValueChange(key, value);
											}} readOnlydisabled={isAllFieldDisabled || (type && type.type == 'edit')}></input>
									</div>
									<div className="col-10">
										<input
											className="custom-input"
											type="text"
											name="mobile"
											value={mobile}
											onChange={(event) => {
												const key = event.target.name;
												const value = event.target.value;
												onFormValueChange(key, value);
											}}
											disabled={isAllFieldDisabled || (type && type.type == 'edit')}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-12">
								<p className="input-label">Primary Email Id<b style={{ color: 'red' }}>*</b></p>
								<input
									className="custom-input"
									type="email"
									name="primaryEmail"
									value={primaryEmail}
									onChange={(event) => {
										const key = event.target.name;
										const value = event.target.value;
										onFormValueChange(key, value);
									}}
									disabled={isAllFieldDisabled || (type && type.type == 'edit')}
								/>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="row">
							<div className="col-md-4 col-12">
								<p className="input-label">Secondary Email Id</p>
								<input
									className="custom-input"
									type="email"
									name="secondaryEmails"
									value={secondaryEmails}
									onChange={(event) => {
										const key = event.target.name;
										const value = event.target.value;
										onFormValueChange(key, value);
									}}
									disabled={isAllFieldDisabled}

								/>
							</div>
							<div className="col-md-4 col-12">
								<p className="input-label">Username<b style={{ color: 'red' }}>*</b></p>
								<input
									className="custom-input"
									type="text"
									name="username"
									value={username}
									onChange={(event) => {
										const key = event.target.name;
										const value = event.target.value;
										onFormValueChange(key, value);
									}}
									disabled={isAllFieldDisabled || (type && type.type == 'edit')}
								/>
							</div>
							{passwordField}
						</div>
					</div>
				</div>
				<div>
					<div className="educator-form-title mt-1">
						<p className="headingTheme">Locality</p>
					</div>
					<div className="row mx-0">
						<div className="col-md-3 col-12">
							<p className="input-label">Country</p>
							<select className="custom-input" value={country} name="country" onChange={(event) => {
								const key = event.target.name;
								const value = event.target.value;
								onFormValueChange(key, value);
							}} required disabled={isAllFieldDisabled || (type && type.type == 'edit')}
							>
								<option value="">Select country</option>
								{countryList.map((country) => (
									<option key={country?._id} value={country?.name}>
										{country?.name}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-3 col-12">
							<p className="input-label">State</p>
							<select className="custom-input" value={stateProvince} name="stateProvince" onChange={(event) => {
								const key = event.target.name;
								const value = event.target.value;
								onFormValueChange(key, value);
							}} disabled={!country || isAllFieldDisabled || !statesList.length} required>
								<option value="">Select State</option>
								{statesList.map((state) => (
									<option key={state} value={state}>
										{state}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-3 col-12">
							<p className="input-label">City</p>
							<select className="custom-input" name='city' value={city} onChange={(event) => {
								const key = event.target.name;
								const value = event.target.value;
								onFormValueChange(key, value);
							}} disabled={!stateProvince || isAllFieldDisabled || !citiesList.length} required>
								<option value="">Select City</option>
								{citiesList.map((city) => (
									<option key={city} value={city}>
										{city}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-3 col-12">
							<p className="input-label">Postal Code</p>
							<input className="custom-input" type="number" name="postalCode" value={postalCode} onChange={(event) => {
								const key = event.target.name;
								const value = event.target.value;
								onFormValueChange(key, value);
							}} required disabled={isAllFieldDisabled}></input>
						</div>
					</div>
				</div>
				<div>
					<div className="educator-form-title mt-1">
						<p className="headingTheme">Academic Profile</p>
					</div>
					<div className="row mx-0">
						<div className="col-md-3 col-12">
							<p className="input-label">Select College</p>
							<div className="position-relative">
								<input className="custom-input" type="text" value={collegeName} onClick={handleOpen} {...register("collegeName", { required: true, maxLength: 50 })} readOnly disabled={isAllFieldDisabled || (type && type.type == 'edit')}/>
								{errors.collegeName && errors.collegeName.type === "required" && <span className="validationError">This field is required</span>}
								<div className="down-arrow">
									{" "}
									<img src={downArrow} width={"12px"} height={"13px"} marginTop={"5px"} />
								</div>
							</div>
						</div>
						<div className="col-md-3 col-12">
							<p className="input-label">Qualification</p>
							<input className="custom-input" type="text" name="qualification" value={qualification} onChange={(event) => {
								const key = event.target.name;
								const value = event.target.value;
								onFormValueChange(key, value);
							}} disabled={isAllFieldDisabled}></input>
						</div>
						<div className="col-md-3 col-12">
							<p className="input-label">Upload Photo 150 px X 150 px</p>
							<label htmlFor="filePicker" className="d-flex align-items-center justify-content-center custom-input" style={{ padding: "5px 10px", color: "#707070" }}>
								Browse or Upload
							</label>
							<div className="mt-0">{selectedFile && <p>{selectedFile.name}</p>}</div>
							<input id="filePicker" style={{ visibility: "hidden" }} type={"file"} onChange={handleFileInputChange} />
						</div>
						<div className="col-md-3 col-12"></div>
					</div>
				</div>
				<div className="text-danger px-2">
					<i>Note:* Marked Fields are mandatory!</i>
				</div>
			</div>
			{type?.type !== 'view' ?
				<div className="form-submit-card">
					<button className="save-btn" onClick={onSaveCollegeAdmin}>
						{type?.type === 'edit' ? 'Update' : 'Save'}
					</button>
					<button className="cancel-btn" onClick={onResetCollegeAdmin}>
						Reset
					</button>
				</div>
				: <></>
			}
			<Modal open={openCollege} onClose={handleClose} centered backdrop="static">
				<SelectCollegeModal {...{ handleClose, collegeId, setCollegeName }} />
			</Modal>
		</div>
	);
}
