import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import NewPassword from '../NewPassword/NewPassword';
import { signIn, completeNewPassword, verifyCurrentUserAttribute } from '../../../Services/AuthService';
import Snackbar from '@mui/material/Snackbar';
import { Alert, AlertTitle } from '@mui/material';
import './Login.scss';
import { readService } from '../../../Services/HttpService';
import blueCurve from '../../../Assets/login/blueCurve.svg';
import zlippLogo from '../../../Assets/img/zlipp-logo.png';
import appleStoreImg from '../../../Assets/login/app.svg';
import playStoreImg from '../../../Assets/login/play.png';
import mobileImg from '../../../Assets/login/mobile.png';
import eyeSolid from '../../../Assets/login/eye-solid.svg';
import eyeSlash from '../../../Assets/login/eye-slash-solid.svg';
import loginDesktop from '../../../Assets/login/login-desktop.png';
import ZlipplogoNew from '../../../Assets/login/zlipp-logo-2x.png';
import resetPasswordImg from '../../../Assets/login/resetPasswordKey.png';
import passIcon from "../../../Assets/commonImg/view.svg";



export default function Login() {

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [passwordType, setPasswordType] = useState("password");
  const [showModal, setShowModal] = useState(false);
  const [errorCatch, setError] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isNewPasswordRequired, setNewPasswordRequired] = useState(false)
  const [authUser, setAuthUser] = useState(null)
  const [snackbarState, setSnackbarState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
    title: '',
    message: '',
    alertSeverity: 'success',
    open: false
  });

  const { vertical, horizontal, open, message, alertSeverity, title } = snackbarState;
  const navigate = useNavigate();

  const changePage = (route) => {
    navigate(route)
  }

  async function resendConfirmationCode(username) {
    try {
      await Auth.resendSignUp(username);
      console.log('code resent successfully');
    } catch (err) {
      console.log('error resending code: ', err);
    }
  }

  const usernameHandler = (e) => {
    setUsername(e.target.value)
  }

  const passwordHandler = (e) => {
    setPassword(e.target.value)
  }

  const closeModal = () => {
    setShowModal(false);
    setError(!errorCatch)
  }

  const onForgotPasswordClick = () => {
    navigate('./forgot-password')
  }


  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const getMentorApprovalDetails = async (mentorId) => {
    let params = {
      mentorId: mentorId
    }
    readService('/mentor', params).then((res) => {
      if (res) {
        if (res.data.approved) {
          navigate('/course-overview') // if mentor is approved navigate to create course screen
          // dispatch(setLoginStatus(true))
        } else {
          navigate('/mentor-dashboard', { state: { mentorId: mentorId } }); // if mentor is not approved navigate to mentor dashboard
        }
      }
    })
  }

  const toggleShowPassword = () => {
    setShowPassword(showPassword => !showPassword)
  }

  const handleForgotPassword = () => {
    navigate('/forgotpassword');
  }

  const onNewPasswordHandler = async (newPassword) => {
    if (authUser && newPassword) {
      try {
        const user = await completeNewPassword(authUser, newPassword);
        setNewPasswordRequired(false);
        setAuthUser(user);
      } catch (error) {
        console.log(`🚀 ~ onNewPasswordHandler ~ error:`, error)
      }
    }
  }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarState({ ...snackbarState, open: false, message: '' });
  };



  const newPasswordContain = <NewPassword onNewPasswordHandler={onNewPasswordHandler} ></NewPassword>;
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};

  const loginContain = <div className='container-fluid login-container p-0'>
    <div id="login_bg">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-12 d-flex justify-content-center forgot-backg">
            <div className="form-content d-flex flex-column mt-5 pb-3 ">
              <div className="loginFormContent1 my-auto text-center px-4">
                <h5 className="text-center">Welcome to ZLIPP LMS</h5>
                <p className="text-center"><b> India's leading online learning platform for all medical aspirants. Download the application by
                  clicking on the link below and learn anytime, anywhere.</b></p>
                <img className="img-fluid backg-img2 mt-3" src={loginDesktop} alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="row align-items-center new-passwords">
              <div className="mx-auto col-10 col-md-8 col-lg-6">
                <div className="row login-ryt justify-content-center"><img className="text-center zlipp-logo img-fluid"
                  src={ZlipplogoNew} alt="" /></div>
                <div className="form-group">
                  <h4>LOGIN</h4>
                </div>
                <div className="form-group usernam-password">
                  <div> <label id="u_name" className="login-labels"> Username</label></div>
                  <div className="form-group mb-0 pb-0 newPasswordInput position-relative">
                    {/* <input id="user_name" type="text" placeholder="Username" name="username"
                                    className="form-control my-0 py-0 new-pass-placeholder"  /> */}
                    <input className='input-block form-control my-0 py-0 new-pass-placeholder' id="user_name" type='text' placeholder='User name' onChange={usernameHandler}></input>
                  </div>
                </div>
                <div className="form-group mt-0 usernam-password">
                  <div><label className="mb-1 login-labels" for="">Password</label></div>
                  <div className="form-group mb-0 pb-0 newPasswordInput position-relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    className='input-block form-control my-0 py-0 new-pass-placeholder'
                    placeholder='password'
                    onChange={passwordHandler}
                  />

                    <img
                      src={passIcon}
                      alt="Toggle Password Visibility"
                      onClick={togglePasswordVisibility}
                      className="password-icon"
			              />
                  </div>
                </div>

                <div className="d-flex flex-wrap justify-content-between align-items-center">
                  <div className="d-flex mb-1 mr-1 mt-2">
                    <div className="d-flex form-check">
                      <input type="checkbox" className="form-check-input form-check-input mt-2" name="device_checked" id="device_check" />
                      <label className="form-check-label" id="remember_device">Remember
                        Device</label>
                    </div>
                  </div>

                  <div className="d-flex mt-2">
                    <div className="d-flex float-right">
                      {/* <div className="forgot-password" id="forget_password"
                                           >Forgot Password?</div> */}

                      <a className="text-start" id="forget_password" onClick={handleForgotPassword}
                      >Forgot Password?</a><br />

                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <button className="btn btn-primary btn-customized" type="submit" onClick={signInHandler}>
                    <input type="submit" value="Login" className="btn btn-back btn-customized" id="login_btn" onClick={signInHandler} />
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  async function signInHandler() {
    try {
      const authUser = await signIn(username, password);
      if (authUser) {
        setAuthUser(authUser);
        if (authUser && authUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          console.log("🚀 ~ signInHandler ~ authUser:", authUser)
          setNewPasswordRequired(true)
        }
        // else if (authUser?.attributes?.email_verified !== false) {
        //   const isOtpSend = await verifyCurrentUserAttribute('email');
        //   if (isOtpSend) {
        //     setSnackbarState({ ...snackbarState, open: true, title: 'Your Email Address not Verified', message: 'Verification code send successfully please check Email Address' });

        //     navigate('../verification-code', { state: { attribute: 'email' } });
        //   }
        // }
        else if (authUser) {
          navigate('../home/dashboard');
          setNewPasswordRequired(false);
        }
      }
      // setShowSpinner(true)
      // console.log(username, password)
      // const user = await Auth.signIn(username, password);
      // console.log(`🚀 ~ signIn ~ user:`, user)
      // getUserRole().then((res) => {
      //   if (res) {
      //     if (res === 'admins') {
      //       // dispatch(setLoginStatus(true))
      //       navigate('/admin-dashboard')
      //     }
      //     else {
      //       getMentorId().then((res) => {
      //         if (res) {
      //           getMentorApprovalDetails(res)
      //         }
      //       })
      //     }
      //     setShowSpinner(false)
      //   }
      // }).catch((error) => {
      //   setShowSpinner(false)
      //   console.log('login successfull', error);
      // })
    }
    catch (error) {
      setShowSpinner(false)
      if (error.code === 'UserNotConfirmedException') {
        resendConfirmationCode(username);
        navigate('/otp-verification', {
          state: {
            username: username,
            password: password,
            isUserNotConfirmed: true
          }
        });
        return;
      }
      setShowModal(!showModal);
      setError(!errorCatch)
      setConfirmationMsg("Email id or Password entered is wrong or doesn't exists!!");
      console.log('error signing in', error);
    }
  }

  return (
    <>
      {
        isNewPasswordRequired
          ? newPasswordContain
          : loginContain
      }

      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          variant="filled"
          sx={{ width: '100%' }}>
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Snackbar>

    </>
  )
}
